@import "../../scss/copy.scss";
@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";
@import "../../scss/common.scss";

.cta-button {
  @include copy-md-strong;
  color: $color-on-white-full;
  background-color: $color-attention;
  padding: $distance-static-md $distance-static-lg $distance-static-md $distance-dynamic-lg;
  display: inline-flex;
  text-decoration: none;
  border-radius: 50px;
  transition: 0.3s;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: $color-attention-dark;
  }

  &:focus-visible {
    @include outline;
  }

  &__icon {
    margin-left: $distance-static-lg;
    transform: rotate(-90deg);
    svg {
      fill: $color-on-white-full;
    }

    width: 14px;
    @include media-xl {
      width: 16px;
    }
  }
}
