.soundbox-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &__images-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }

  &__image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: bottom;
  }
}
