@import "../../scss/variables.scss";
@import "../../scss/copy.scss";
@import "../../scss/colors.scss";

.disclaimer {
  padding: $distance-dynamic-lg;
  @include copy-sm;
  color: $color-on-light-high;

  h1 {
    @include headline;
    margin-bottom: $distance-static-lg;
    text-align: center;
  }

  p {
    @include copy-sm;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: $distance-static-lg;
  }
}
