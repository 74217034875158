@import "../../scss/common.scss";
@import "../../scss/colors.scss";

.control-button {
  padding-top: 100%;
  position: relative;

  &:focus-visible {
    @include outline;
    border-radius: 50%;
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;
    img {
      cursor: default;
      &:hover {
        filter: grayscale(100%);
      }
    }
  }

  &__image {
    overflow: hidden;
    filter: grayscale(100%);
    transition: 0.15s;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: $color-dark-primary;

    &--active {
      border: 4px solid $color-dark-primary;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);
      filter: grayscale(0%);
    }

    &:hover {
      filter: grayscale(0%);
    }
  }
}
