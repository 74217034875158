@import "../../scss/variables.scss";
@import "../../scss/media.scss";

.soundbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $distance-static-sm;

  @include media-sm {
    padding: $distance-static-md;
  }
}
