@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/media.scss";

.basic-button {
  padding: 16px 28px;

  &:active {
    // transform: scale(calc(100$ - 2px));
    padding: 14px 26px;
    margin: 2px;
  }
  border: none;
  cursor: pointer;
  color: $color-on-white-full;
  transition: 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
  @include copy-md;

  &__icon {
    margin-right: 10px;
    display: inherit;
    width: 19px;
    svg {
      fill: $color-on-white-full;
    }

    @include media-lg {
      width: 23px;
    }
  }

  &:disabled {
    cursor: default;
  }
}
