@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/common.scss";

.language-picker {
  position: relative;
  display: inline-block;

  &__button {
    background-color: transparent;
    cursor: pointer;
    transition: 0.15s;
    @include copy-sm-strong;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
      @include outline;
    }

    &--light {
      color: $color-light-secondary;
      svg {
        fill: $color-light-secondary;
      }

      &:focus-visible,
      &:active,
      &:hover {
        color: $color-light-primary;
        svg {
          fill: $color-light-primary;
        }
      }
    }

    &--grey {
      color: $color-on-light-medium;
      svg {
        fill: $color-on-light-medium;
      }

      &:focus-visible,
      &:active,
      &:hover {
        color: $color-dark-primary;

        svg {
          fill: $color-dark-primary;
        }
      }
    }
  }

  &__icon {
    width: 15px;
    margin-right: $distance-static-sm;

    svg {
      display: block;
    }
  }

  &__select-list {
    position: absolute;
    transform: translateY(100%);
    max-height: 90vh;
    background-color: $color-light-primary;
    left: -1000px;
    bottom: -1000px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
    z-index: 100;

    li {
      text-align: center;
      @include copy-sm;
      padding: $distance-static-xs;
      cursor: pointer;
      color: $color-on-light-high;
      transition: background-color 0.15s;
      padding: 8px 15px;

      &:hover {
        background-color: $color-dark-primary-shallow;
      }

      &:focus {
        background-color: $color-dark-primary-shallow;
        outline: none;
      }
    }

    &--hide {
      visibility: hidden;
    }
  }
}
