@import "../../scss/colors.scss";
@import "../../scss/common.scss";
@import "../../scss/copy.scss";

.tag {
  cursor: pointer;
  background-color: $color-light-primary;
  color: $color-on-light-medium;
  padding: 8px 12px;
  border-radius: 20px;
  @include copy-sm;

  &:hover:not(.tag--active) {
    background-color: $color-dark-primary-shallow;
  }

  &--active {
    background-color: $color-dark-primary;
    color: $color-on-white-full;
  }

  &:not(.tag--disabled):focus {
    @include outline;
  }

  &--disabled {
    outline: none;
    cursor: default;

    &:hover:not(.tag--active) {
      background-color: $color-light-primary;
    }
  }
}
