html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "RecklessNeue", sans-serif;

}

* {
  margin: 0;
  padding: 0;
  font-weight: normal;
  outline: none;
}

ol,
ul {
  list-style: none;
}



@font-face {
  font-family: "RecklessNeue";
  src: local("RecklessNeue"), url("../src/static/fonts/RecklessNeue.woff") format("truetype");
  font-display: swap;
}


input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
