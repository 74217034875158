@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/common.scss";

@mixin animation-mixin($name, $translate) {
  @keyframes #{$name} {
    0% {
      transform: translateY($translate);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

.quick-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__item {
    padding: 4px 0px;
  }

  &__button-wrapper {
    position: relative;
  }

  &__text {
    text-align: center;
    display: none;
    margin-top: $distance-static-xs;
    @include copy-sm;

    @include media-sm {
      display: flex;
    }
  }

  &__icon {
    width: 17px;
    margin-right: $distance-static-xs;

    &--audio-active {
      svg {
        display: block;
        @include volumeAnimation;
      }
    }

    svg {
      fill: $color-dark-primary;
    }

    @include media-xl {
      width: 20px;
    }
  }

  &__button {
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid $color-dark-primary;
    cursor: pointer;
    position: relative;

    @include media-xl {
      width: 56px;
      height: 56px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $color-dark-primary;
  }

  &__menu {
    background-color: $color-light-primary;
    position: absolute;
    padding: 4px 6px;
    border-radius: 28px;
    bottom: -8px;
    left: -6px;
    box-shadow: 0px 28.7442px 45.5116px rgba(0, 0, 0, 0.14), 0px 10.7791px 55.093px rgba(0, 0, 0, 0.12),
      0px 13.1744px 17.9651px rgba(0, 0, 0, 0.2);

    @include media-xl {
      border-radius: 32px;
    }

    div:nth-of-type(1) {
      @include animation-mixin(slide-1, 200%);
      animation: slide-1 0.25s;
    }

    div:nth-of-type(2) {
      @include animation-mixin(slide-2, 100%);
      animation: slide-2 0.25s;
    }
  }
}
