@import "../../scss/copy.scss";
@import "../../scss/colors.scss";
@import "../../scss/common.scss";

.text-button {
  @include copy-sm;
  color: $color-on-light-medium;
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;

  &:visited {
    color: $color-on-light-low;
  }

  &:hover {
    color: $color-dark-primary;
  }

  &:focus-visible {
    @include outline;
  }
}
