@import "../../scss/colors.scss";
@import "../../scss/media.scss";

.modal {
  background-color: $color-on-light-medium;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &__content {
    max-height: calc(100% - 16px);
    overflow-y: auto;
    background-color: $color-light-primary;
    margin: 8px;
    width: 100%;

    &--default {
      max-width: 400px;

      @include media-xl {
        max-width: 600px;
      }
    }

    &--text {
      max-width: 600px;

      @include media-xl {
        max-width: 1000px;
      }
    }
  }
}
