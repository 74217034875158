@import "./colors.scss";

@mixin outline {
  outline: $color-on-light-medium solid 2px;
  outline-offset: 2px;
  transition: outline 0s, all 0.3s;
}

@mixin volumeAnimation {
  rect:nth-of-type(1) {
    animation: volume 2s infinite;
  }

  rect:nth-of-type(2) {
    animation: volume 1.5s infinite;
  }

  rect:nth-of-type(3) {
    animation: volume 2.2s infinite;
  }

  @keyframes volume {
    0% {
      height: 100px;
    }

    25% {
      height: 150px;
    }

    50% {
      height: 80px;
    }

    70% {
      height: 60px;
    }

    90% {
      height: 250px;
    }

    0% {
      height: 100px;
    }
  }
}
