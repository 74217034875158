@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/common.scss";

.soundbox-carousel-button {
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 18px;

  @include media-sm {
    padding: 28px 30px;
  }

  @include media-md {
    padding: 15px 15px;
  }

  @include media-lg {
    padding: 15px $distance-dynamic-xl;
  }

  @include media-xl {
    padding: 43px 36px;
  }

  svg {
    display: block;
  }

  &:focus-visible {
    @include outline;
  }

  &:disabled {
    cursor: default;
    background-color: $color-light-secondary;
    color: $color-on-light-shallow;
    svg {
      fill: $color-on-light-shallow;
    }
  }

  &__icon {
    display: block;
    width: 17px;

    @include media-xl {
      width: 22px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-xl {
      align-items: flex-start;
    }
  }

  &__title {
    display: none;
    @include copy-md-strong;
    margin-top: $distance-static-sm;

    @include media-md {
      display: block;
    }

    @include media-xl {
      margin-top: 0px;
    }
  }

  &:disabled &__description {
    display: none;
  }

  &__description {
    margin-top: $distance-static-xs;
    display: flex;

    @include media-md {
      margin-top: 0px;
    }

    @include copy-sm;
  }

  &__subtext {
    display: none;

    @include media-md {
      display: block;
    }
  }

  &--next {
    color: $color-on-white-full;
    background-color: $color-dark-primary;

    svg {
      fill: $color-on-white-full;
    }

    &:hover {
      background-color: $color-dark-secondary;
    }

    &:disabled:hover {
      background-color: $color-light-secondary;
    }

    &:active {
      background-color: $color-dark-secondary;
    }

    &:focus-visible {
      background-color: $color-dark-secondary;
    }

    @include media-xl {
      flex-direction: row-reverse;
      .soundbox-carousel-button__text {
        margin-right: $distance-dynamic-xl;
      }
    }
  }

  &--back {
    color: $color-on-light-medium;
    background-color: $color-light-secondary;

    svg {
      fill: $color-on-light-medium;
    }

    .soundbox-carousel-button__icon {
      transform: rotate(180deg);
    }

    @include media-xl {
      flex-direction: row;
      .soundbox-carousel-button__text {
        margin-left: 20px;
      }
    }
  }
}
