@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";
@import "../../scss/common.scss";

.sound-selection {
  color: $color-on-light-medium;
  text-align: center;

  &__title {
    @include headline;
    padding: $distance-dynamic-lg 0px $distance-static-xs;
    margin: 0 auto;

    @include media-xl {
      max-width: 545px;
    }
  }

  &__subtitle {
    @include copy-md;
    padding: 0px $distance-static-lg $distance-dynamic-lg;
    margin: 0 auto;

    @include media-xs {
      padding: 0px $distance-dynamic-xl $distance-dynamic-lg;
    }
    @include media-xl {
      padding-bottom: $distance-dynamic-xl;
    }
  }
}

.sound-selection-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: $distance-dynamic-lg;
  padding-bottom: $distance-static-lg;

  @include media-xl {
    padding-top: $distance-dynamic-xl;
  }

  &__button {
    @include copy-md-strong;
  }

  &__first-button-wrapper {
    margin-bottom: $distance-static-sm;
  }
}

.area-selector {
  display: flex;
  justify-content: space-around;
  padding: 0 12px $distance-dynamic-lg;

  @include media-xs {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include media-xl {
    padding-left: 100px;
    padding-right: 110px;
    padding-bottom: $distance-dynamic-xl;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon-wrapper {
    width: 76px;

    @include media-xl {
      width: 88px;
    }
  }
}

.area-selector-description {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  min-width: 80px;

  @include media-xs {
    min-width: 120px;
  }

  @include media-lg {
    min-width: 130px;
  }

  span {
    color: $color-on-light-medium;
    @include copy-md-strong;
  }

  &__icon {
    width: 20px;
    margin-right: 8px;
    svg {
      @include volumeAnimation;
      fill: $color-dark-primary;
    }
  }
  

  &--active {
    span {
      color: $color-on-light-high;
    }
  }
}

.sound-range {
  display: flex;
  align-items: center;
  padding: 24px;
  margin: 0 auto;
  max-width: 350px;

  &__icon {
    width: 20px;

    svg {
      display: block;
      fill: $color-on-light-low;
    }

    @include media-xl {
      width: 23px;
    }
  }

  input {
    margin: 0 20px;
  }
  &__icon:nth-child(1) {
    width: 14px;

    @include media-xl {
      width: 16px;
    }
  }
}
