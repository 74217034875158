@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/copy.scss";
@import "../../scss/media.scss";
@import "../../scss/common.scss";

.soundbox-header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: $distance-static-sm;
  bottom: "auto";

  &--menu-open {
    bottom: 0;
  }

  @include media-sm {
    padding: $distance-static-lg;
  }

  .soundbox-header {
    z-index: 100;

    @include media-sm {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      z-index: auto;
    }

    &__avatar {
      z-index: 5;
      align-items: center;
      justify-content: space-between;
      padding: $distance-static-sm $distance-static-lg;
      background-color: $color-light-primary;
      display: none;
      min-height: 64px;
      max-width: 360px;

      @include media-sm {
        display: flex;
        flex-grow: 1;
      }

      @include media-md {
        max-width: 400px;
      }

      @include media-xl {
        max-width: 600px;
        min-height: 88px;
        padding: $distance-dynamic-lg-mobile $distance-dynamic-lg;
      }
    }

    &__text {
      color: $color-on-light-medium;
      @include copy-md-strong;
    }

    &__menu {
      z-index: 15;
      background-color: $color-light-primary;
      flex-grow: 1;

      @include media-sm {
        max-width: 360px;
      }

      @include media-md {
        max-width: 400px;
      }

      @include media-xl {
        max-width: 600px;
      }
    }

    &__separator {
      margin: 0px;
      background-color: $color-light-secondary;
    }

    .soundbox-logo {
      max-width: 70px;
      flex-shrink: 1;
      cursor: pointer;

      &:focus-visible {
        @include outline;
      }

      @include media-xs {
        max-width: 100px;
      }

      @include media-md {
        max-width: 130px;
      }

      &--small-screen {
        @include media-sm {
          display: none;
        }
      }

      &__img {
        width: 100%;
      }
    }

    .sound-option-icon {
      width: 17px;
      display: inline-block;
      margin-right: $distance-static-sm;
      svg {
        display: block;
        fill: $color-dark-primary;
      }

      &--active {
        svg {
          @include volumeAnimation;
        }
      }

      @include media-xl {
        width: 20px;
      }
    }

    .soundbox-options {
      display: flex;
      padding: $distance-static-sm $distance-static-lg;
      align-items: center;
      justify-content: space-between;

      &__button {
        flex-shrink: 0;
      }

      @include media-xl {
        padding: $distance-dynamic-lg-mobile $distance-dynamic-lg;
      }
    }

    .product-specification-wrapper {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;

      @include media-sm {
        justify-content: space-between;
      }

    }

    .product-specification {
      color: $color-on-light-high;
      display: flex;
      flex-direction: column;
      text-align: right;
      margin: 0px $distance-static-sm;

      @include media-sm {
        margin: 0px;
        text-align: left;
      }

      &__name {
        @include copy-md-strong;
      }

      &__description {
        @include copy-sm;
      }
    }

    .sound-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $distance-static-sm $distance-static-lg;

      @include media-xl {
        padding: $distance-dynamic-lg-mobile $distance-dynamic-lg;
      }

      &--hidden {
        display: none;
      }

      &__buttons {
        flex-shrink: 0;
        button:last-child {
          margin-left: $distance-static-sm;
          @include media-xl {
            margin-left: $distance-static-md;
          }
        }
      }

      .sound-options-description {
        display: flex;
        flex-direction: column;
        color: $color-on-light-high;

        &__title {
          @include copy-md-strong;
        }

        &__subtitle {
          @include copy-sm;
        }
      }
    }
  }

  .black_blur {
    inset: 0px;
    position: absolute;
    z-index: 8;
    background-color: $color-on-light-medium;
  }

  .menu-select-product {
    flex-grow: 1;
    background-color: $color-light-tertiary;
    z-index: 50;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;

    &__title {
      @include copy-md;
      color: $color-on-light-medium;
      text-align: center;
      padding: $distance-static-md $distance-dynamic-lg $distance-dynamic-lg;

      @include media-sm {
        padding-top: $distance-dynamic-xl;
      }

      @include media-lg {
        padding-bottom: $distance-dynamic-xl;
        padding-top: 45px;
      }
    }

    &__select-wrapper {
      width: 50%;

      @include media-sm {
        width: 25%;
      }

      @include media-lg {
      }
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;
      padding-top: $distance-dynamic-lg;

      @include media-lg {
        padding-top: $distance-dynamic-xl;
      }
    }

    &__tags-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: $distance-dynamic-lg;

      div {
        margin-right: $distance-static-sm;

        &:last-child {
          margin-right: 0;
        }
      }
      @include media-lg {
        padding-bottom: $distance-dynamic-xl;
      }
    }

    &__links-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: $distance-static-md;
      padding-top: $distance-static-lg;
    }

    &__links-wrapper-item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
