// Font size
$font-size-lg: 32px;
$font-size-lg-mobile: 24px;
$font-size-md: 20px;
$font-size-md-mobile: 16px;
$font-size-sm: 16px;
$font-size-sm-mobile: 12px;

// Font weight
$font-weight-normal: normal;
$font-weight-strong: bold;

// Line height
$line-height-lg: 42px;
$line-height-lg-mobile: 32px;
$line-height-md: 28px;
$line-height-md-mobile: 22px;
$line-height-sm: 22px;
$line-height-sm-mobile: 16px;

// Distances - static
$distance-static-lg: 16px;
$distance-static-md: 12px;
$distance-static-sm: 8px;
$distance-static-xs: 4px;

// Distances - dynamic
$distance-dynamic-xxl: 80px;
$distance-dynamic-xxl-mobile: 44px;
$distance-dynamic-xl: 44px;
$distance-dynamic-xl-mobile: 28px;
$distance-dynamic-lg: 28px;
$distance-dynamic-lg-mobile: 16px;

//Breakpoints
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1302px;
$screen-xl: 1920px;
