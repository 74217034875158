@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";

.soundbox-carousel-control {
  &__panel {
    display: flex;
    justify-content: space-between;
    background-color: $color-light-primary;
  }

  &__cta-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: $distance-static-lg;

    @include media-xl {
      padding-bottom: $distance-static-md;
    }
  }

  z-index: 5;
  position: absolute;
  bottom: $distance-static-sm;
  left: $distance-static-sm;
  right: $distance-static-sm;

  @include media-sm {
    bottom: $distance-static-lg;
    left: $distance-static-lg;
    right: $distance-static-lg;
  }
}
