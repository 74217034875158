
// Surface color
$color-dark-primary: rgba(0, 137, 125, 1);
$color-dark-primary-shallow: rgba(0, 137, 125, 0.2);
$color-dark-primary-medium: rgba(0, 137, 125, 0.4);
$color-dark-secondary: rgba(14, 117, 110, 1);
$color-attention: rgba(239, 139, 52, 1);
$color-attention-dark: rgba(210, 120, 41, 1);
$color-light-secondary: rgba(229, 229, 229, 1);
$color-light-tertiary: rgba(242, 242, 242, 1);
$color-light-primary: rgba(255, 255, 255, 1);
$color-success-green: rgb(75, 181, 67);

// Text color
$color-on-light-accent: rgba(196, 196, 196, 1);
$color-on-light-full: rgba(0, 0, 0, 1);
$color-on-light-high: rgba(0, 0, 0, 0.8);
$color-on-light-medium: rgba(0, 0, 0, 0.6);
$color-on-light-low: rgba(0, 0, 0, 0.4);
$color-on-light-shallow: rgba(0, 0, 0, 0.2);
$color-on-white-full: rgba(255, 255, 255, 1);

