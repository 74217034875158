@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/common.scss";
@import "../../scss/colors.scss";
@import "../../scss/media.scss";

.select {
  position: relative;

  &__button {
    width: 100%;
    background-color: $color-light-primary;
    border: 2px solid $color-dark-primary;
    padding: 6px $distance-dynamic-xl-mobile;
    @include copy-sm-strong;
    transition: 0.15s;
    color: $color-on-light-high;
    cursor: pointer;

    @include media-xl {
      padding: 5px $distance-dynamic-xl-mobile;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &:active,
    &:hover {
      background-color: $color-dark-primary-shallow;
    }

    &:focus {
      @include outline;
    }
  }

  &__icon {
    width: 16px;
    margin-left: $distance-static-md;

    @include media-lg {
      width: 18px;
    }

    svg {
      fill: $color-dark-primary;
      display: block;
    }
  }

  &__select-list {
    position: absolute;
    transform: translateY(100%);
    max-height: 90vh;
    background-color: $color-light-primary;
    left: 0;
    bottom: 0px;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    z-index: 200;

    li {
      text-align: center;
      @include copy-sm-strong;
      padding: $distance-static-xs;
      cursor: pointer;
      transition: background-color 0.15s;
      color: $color-on-light-high;

      &:hover {
        background-color: $color-dark-primary-shallow;
      }

      &:focus {
        background-color: $color-dark-primary-shallow;
        outline: none;
      }
    }

    &--hide {
      visibility: hidden;
    }
  }
}
