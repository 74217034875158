@import "../../scss/colors.scss";
@import "../../scss/common.scss";
@import "../../scss/media.scss";

.icon-button {
  border-radius: 50%;
  border: 2px solid $color-light-secondary;
  background-color: transparent;
  transition: 0.2s;
  text-align: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    border-color: $color-dark-primary;
  }

  &:active {
    background-color: $color-dark-primary-shallow;
  }

  &:focus-visible {
    @include outline;
    border-color: $color-dark-primary;
  }

  svg {
    display: block;
    fill: $color-dark-primary;
  }

  @include media-xl {
    width: 56px;
    height: 56px;
  }
}
