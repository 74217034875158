@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";
@import "../../scss/colors.scss";

.select-product {
  &__title {
    text-align: center;
    padding: $distance-dynamic-lg $distance-dynamic-lg $distance-static-sm;
    @include headline;
    color: $color-on-light-high;

    @include media-sm {
      padding: $distance-dynamic-xxl-mobile $distance-dynamic-xxl-mobile $distance-dynamic-lg-mobile;
    }

    @include media-md {
      padding-bottom: 13px;
    }

    @include media-xl {
      padding-top: 60px;
      padding-bottom: $distance-static-sm;
    }
  }

  &__subtitle {
    text-align: center;
    padding: 0px $distance-dynamic-lg $distance-dynamic-lg;
    @include copy-md;
    color: $color-on-light-medium;

    @include media-sm {
      padding: 0px $distance-dynamic-xxl-mobile 36px;
    }

    @include media-xl {
      padding-bottom: $distance-dynamic-xxl-mobile;
    }
  }

  &__tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: $distance-dynamic-lg;

    @include media-sm {
      padding-bottom: 36px;
    }

    @include media-xl {
      padding-bottom: $distance-dynamic-xxl-mobile;
    }
  }

  &__tag {
    margin-right: $distance-static-sm;
    @include media-sm {
      margin-right: $distance-static-md;
    }
  }

  &__select-wrapper {
    width: 50%;

    @include media-sm {
      width: 25%;
    }

    @include media-lg {
      padding: 0px $distance-static-md;
    }
  }

  &__button-wrapper {
    padding: $distance-dynamic-lg 0px $distance-dynamic-xl;
    display: flex;
    justify-content: center;

    @include media-sm {
      padding-top: 36px;
    }

    @include media-sm {
      padding-top: $distance-dynamic-xxl-mobile;
    }
  }

  &__bottom-items-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: $distance-static-lg;
    left: 50%;
    transform: translateX(-50%);
  }

  &__bottom-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $distance-static-md;
    &:nth-last-child(1) {
      margin-right: 0px;
    }
  }
}
