@import "./variables.scss";
@import "./media.scss";

@mixin headline {
  font-size: $font-size-lg-mobile;
  line-height: $line-height-lg-mobile;

  @include media-xl {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }
}

@mixin copy-md {
  font-size: $font-size-md-mobile;
  line-height: $line-height-md-mobile;

  @include media-xl {
    font-size: $font-size-md;
    line-height: $line-height-md;
  }
}

@mixin copy-md-strong {
  @include copy-md;
  font-weight: $font-weight-strong;
}

@mixin copy-sm {
  font-size: $font-size-sm-mobile;
  line-height: $line-height-sm-mobile;

  @include media-xl {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
}

@mixin copy-sm-strong {
  @include copy-sm;
  font-weight: $font-weight-strong;
}
