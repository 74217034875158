@import "../../scss/colors.scss";

.loading {
  width: 100%;
  padding-top: 100%;
  position: relative;

  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 4px solid $color-dark-primary;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    animation: spin 0.3s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
