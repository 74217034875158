@import "../../scss/colors.scss";
@import "../../scss/common.scss";
@import "../../scss/media.scss";

.switch-button {
  background-color: $color-light-secondary;
  color: $color-on-light-medium;
  border-radius: 50%;
  padding: 12px;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  width: 48px;
  height: 48px;

  &:disabled {
    cursor: default;
    opacity: 0.65;
    background-color: $color-light-secondary;
    color: $color-on-light-medium;

    svg {
      fill: $color-on-light-medium;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:not(.switch-button--active):not([disabled]):hover {
      background-color: $color-dark-primary-shallow;
    }
  }

  svg {
    fill: $color-on-light-medium;
    display: block;
  }

  &--active {
    background-color: $color-dark-primary;
    color: $color-on-white-full;

    svg {
      fill: $color-on-white-full;
    }
  }

  &:focus-visible {
    background-color: $color-dark-primary-shallow;
    @include outline;
  }

  @include media-xl {
    width: 56px;
    height: 56px;
  }
}
