@import "../../scss/copy.scss";
@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";
@import "../../scss/common.scss";

.scene-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0 5%;
  

  @include media-sm {
    justify-content: space-around;
    padding: 0px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid $color-light-secondary;
    border-radius: 25px;
    padding: $distance-static-xs 22px;
    cursor: pointer;
    transition: 0.3s;

    &:focus-visible {
      @include outline;
    }

    &:hover {
      border-color: $color-dark-primary;
    }

    &:active {
      background-color: $color-dark-primary-shallow;
    }

    @include media-sm {
      display: none;
    }
  }

  &__distance {
    @include copy-md-strong;
    color: $color-dark-primary;
    min-width: auto;

    @include media-xs {
      min-width: 110px;
    }

    text-align: center;
  }

  &__counter {
    color: $color-on-light-medium;
    @include copy-sm;
  }

  &__scene-selector-mobile {
    padding: $distance-static-md $distance-static-md $distance-static-xs $distance-static-md;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-light-primary;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--big-screen {
      display: none;

      @include media-sm {
        display: flex;
      }
    }
  }

  &__mobile-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__images-wrapper {
    display: flex;
    overflow-x: auto;
    justify-content: flex-start;
    max-width: 380px;
    margin-top: 20px;

    @include media-sm {
      margin-top: 0px;
    }

    @include media-md {
      max-width: 500px;
    }

    @include media-lg {
      max-width: 600px;
    }

    @include media-xl {
      max-width: 900px;
    }

    &--big-screen {
      display: none;

      @include media-sm {
        display: flex;
      }
    }
  }
}
