@import "./variables.scss";
@import "./colors.scss";

.button {
  &__primary {
    background-color: $color-dark-primary;

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: $color-dark-secondary;
    }

    &:disabled {
      background-color: $color-light-secondary;
      color: $color-on-light-shallow;
    }
  }

  &__secondary {
    background-color: $color-light-primary;
    color: $color-dark-primary;
    border: 2px solid $color-dark-primary;

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: $color-dark-primary-shallow;
    }

    &:disabled {
      border-color: $color-on-light-shallow;
      color: $color-on-light-shallow;
    }
  }

  &__tertiary {
    color: $color-on-light-medium;
    background-color: $color-light-primary;
    border: 2px solid $color-light-secondary;
  
    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      color: $color-on-light-high;
      background-color: $color-light-tertiary;
      border-color: transparent;
    }

    &:disabled {
      color: $color-on-light-shallow;
      border-color: transparent;
    }
  }
}
