@import "../../scss/variables.scss";
@import "../../scss/copy.scss";
@import "../../scss/colors.scss";

.overlay-time-up {
  padding: $distance-dynamic-lg;

  @include media-lg {
    padding: $distance-dynamic-xl;
  }

  &__title {
    @include headline;
    color: $color-on-light-medium;
    text-align: center;
    padding: $distance-static-xs;
  }

  &__subtitle {
    @include copy-md;
    text-align: center;
    color: $color-on-light-medium;
    margin: 0 auto;
    margin-bottom: $distance-dynamic-lg;

    @include media-lg {
      margin-bottom: $distance-dynamic-xl;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__first-button-wrapper {
    margin-bottom: $distance-static-sm;
  }

  &__button {
    @include copy-md-strong;
  }
}
