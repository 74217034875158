@import "../../scss/colors.scss";
@import "../../scss/common.scss";

.range-input {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: $color-on-light-shallow;
  outline: none;
  border-radius: 5px;
  margin: 0;
  &:focus-visible {
    &::-webkit-slider-thumb {
      @include outline;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    background: $color-dark-primary;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0px 0px 0px 6px $color-dark-primary-shallow;
    }

    &:active {
      box-shadow: 0px 0px 0px 6px $color-dark-primary-medium;
    }
  }
}
