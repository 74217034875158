@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/copy.scss";

.alert {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: calc(100% - 20px);
  background-color: $color-success-green;
  color: $color-on-white-full;
  z-index: 1200;
  padding: $distance-static-sm $distance-static-md;
  border-radius: 5px;
  @include copy-md;
  display: flex;
  align-items: center;
  justify-content: center;

  &__button {
    background-color: transparent;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin-left: $distance-static-lg;

    svg {
      fill: $color-on-white-full;
    }
  }
}
