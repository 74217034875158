@import "../../scss/colors.scss";
@import "../../scss/variables.scss";
@import "../../scss/copy.scss";
@import "../../scss/media.scss";

.selected-product-intro-v1 {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .selected-product-image {
    width: 50%;
    object-fit: cover;
  }

  .selected-product-description {
    width: 50%;
    background-color: $color-dark-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 45px $distance-static-md;

    &__title {
      text-align: center;
      @include headline;
      color: $color-on-white-full;
      margin-bottom: $distance-dynamic-xl-mobile;
      max-width: 450px;

      @include media-sm {
        margin-bottom: $distance-dynamic-xl;
      }
    }

    &__button {
      background-color: $color-attention;
      @include copy-md-strong;
    }

    &__language-picker-wrapper {
      position: absolute;
      bottom: $distance-static-md;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (orientation: portrait) {
    flex-direction: column;

    .selected-product-description {
      width: 100%;
      height: 50%;
    }

    .selected-product-image {
      width: 100%;
      height: 50%;
    }
  }
}
