@import "../../scss/variables.scss";
@import "../../scss//colors.scss";
@import "../../scss/common.scss";

.custom-button {
  display: inline-flex;
  align-items: center;

  &:focus {
    @include outline;
  }

  &__icon {
    margin-right: $distance-static-sm;
  }
}
