@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/variables.scss";
@import "../../scss/media.scss";
@import "../../scss/common.scss";

.scene-description-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  margin: 4px $distance-static-xs;

  &:hover {
    border-color: $color-dark-primary;
  }

  &:focus-visible {
    @include outline;
  }

  &__text {
    @include copy-sm;
    margin-top: $distance-static-xs;
    color: $color-on-light-medium;
  }

  img {
    width: 46px;
    height: 62px;

    @include media-sm {
      width: 44px;
      height: 44px;
    }

    object-fit: cover;
    flex-grow: 1;
    filter: grayscale(100%);

    @include media-xl {
      width: 74px;
      height: 74px;
    }
  }

  &--active {
    background-color: $color-dark-primary;

    .scene-description-image__text {
      color: $color-on-white-full;
    }

    img {
      filter: grayscale(0%);
    }
  }
}
