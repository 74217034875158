@import "../../scss/media.scss";
@import "../../scss/colors.scss";
@import "../../scss/copy.scss";
@import "../../scss/common.scss";
@import "../../scss/variables.scss";

.carousel {
  display: flex;
  transition: 0.3s;
  position: relative;
  z-index: 2;

  &__item {
    padding: 0 $distance-static-xs;
    height: 100%;
    min-width: 0;
    flex: 0 0 50%;
    @include media-sm {
      flex: 0 0 25%;
    }

    @include media-lg {
      padding: 0 $distance-static-sm;
    }
  }

  &__image-wrapper {
    position: relative;
    cursor: pointer;
    padding-top: 66%;
    filter: grayscale(100%);
    border: 4px solid transparent;

    &:focus-visible {
      @include outline;
    }

    @include media-lg {
      padding-top: 60%;
    }

    &--active {
      border: 4px solid $color-dark-primary;
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__title {
    @include copy-md-strong;
    color: $color-on-light-high;
    word-break: break-all;

    &--unselected {
      color: $color-on-light-medium;
    }
  }

  &__subtitle {
    @include copy-sm;
    color: $color-on-light-high;
    margin-bottom: $distance-static-lg;
    &--unselected {
      color: $color-on-light-medium;
    }
  }

  &__text {
    padding-top: $distance-static-lg;
  }
}

.carousel-wrapper {
  padding: 0 12px;

  @include media-sm {
    padding: 0 42px;
  }

  @include media-lg {
    padding: 0 118px;
  }

  @include media-xl {
    padding: 0 200px;
  }

  &__images {
    position: relative;
    overflow: hidden;
  }
}

.carousel-button {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  padding: 14px;
  background-color: $color-light-primary;
  border: 2px solid $color-light-primary;
  color: $color-dark-primary;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  width: 40px;
  height: 48px;

  @include media-lg {
    width: 44px;
    height: 52px;
    padding: 14px;
  }

  &:active,
  &:hover {
    border-color: $color-dark-primary;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
  &:focus-visible {
    @include outline;
  }

  svg {
    display: block;
    fill: $color-dark-primary;
  }

  &__left {
    top: 50%;
    left: $distance-static-sm;
    transform: translateY(-50%);

    @include media-lg {
      left: $distance-static-md;
    }
  }

  &__right {
    top: 50%;
    right: $distance-static-sm;
    transform: translateY(-50%);

    @include media-lg {
      right: $distance-static-md;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}
